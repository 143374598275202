var render = function render(){
  var _vm$value, _vm$value2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "value": _vm.open,
      "persistent": "",
      "max-width": "600"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-account-edit")]), _vm._v(" Vendégbelépés részletei "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_c('v-icon', [_vm._v(" mdi-close ")])], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "mt-4"
  }, [_c('v-row', [_c('v-col', [_c('div', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Belépés helye")]), _c('div', {
    staticClass: "text-body-1"
  }, [_vm._v(_vm._s(_vm.form.entry_gate_name))])]), _c('v-col', [_c('div', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Beléptető felhasználó")]), _c('div', {
    staticClass: "text-body-1"
  }, [_vm._v(_vm._s(_vm.form.entry_user_name))])]), _c('v-col', [_c('div', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Belépés ideje")]), _c('div', {
    staticClass: "text-body-1"
  }, [_vm._v(_vm._s(_vm._f("moment")(_vm.form.entry_datetime, 'lll')))])])], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "mt-4"
  }, [_vm.form.exit_datetime ? _c('v-row', [_c('v-col', [_c('div', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Kilépés helye")]), _c('div', {
    staticClass: "text-body-1"
  }, [_vm._v(_vm._s(_vm.form.exit_gate_name))])]), _c('v-col', [_c('div', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Kiléptető felhasználó")]), _c('div', {
    staticClass: "text-body-1"
  }, [_vm._v(_vm._s(_vm.form.exit_user_name))])]), _c('v-col', [_c('div', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Kilépés ideje")]), _c('div', {
    staticClass: "text-body-1"
  }, [_vm._v(_vm._s(_vm._f("moment")(_vm.form.exit_datetime, 'lll')))])])], 1) : _c('v-row', {
    attrs: {
      "align-content-center": ""
    }
  }, [_c('v-col', [_c('v-autocomplete', {
    attrs: {
      "label": "Kilépés helye",
      "items": _vm.gates,
      "filled": "",
      "item-text": "name",
      "item-value": "element_id",
      "hide-details": !_vm.errors.exit_gate_id,
      "error-messages": _vm.errors.exit_gate_id,
      "dense": ""
    },
    model: {
      value: _vm.form.exit_gate_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "exit_gate_id", $$v);
      },
      expression: "form.exit_gate_id"
    }
  })], 1), _c('v-col', [_c('v-btn', {
    attrs: {
      "color": "primary",
      "depressed": "",
      "block": "",
      "x-large": "",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.exit
    }
  }, [_vm._v("Kiléptetés")])], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "mt-4"
  }, [_c('v-row', [_c('v-col', [_c('div', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Név")]), _c('div', {
    staticClass: "text-body-1"
  }, [_vm._v(_vm._s(_vm.form.name))])]), _c('v-col', [_c('div', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Cég")]), _c('div', {
    staticClass: "text-body-1"
  }, [_vm._v(_vm._s(_vm.value.company_name || '-'))])])], 1), _c('v-row', [_c('v-col', [_c('div', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Születési idő")]), _c('div', {
    staticClass: "text-body-1"
  }, [_vm._v(_vm._s(_vm.form.birth_date ? _vm.$moment(_vm.form.entry_datetime).format('ll') : '-'))])]), _c('v-col', [_c('div', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Cím")]), _c('div', {
    staticClass: "text-body-1"
  }, [_vm._v(_vm._s(_vm.value.address || '-'))])])], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "mt-4"
  }, [_c('v-row', [_c('v-col', [_c('div', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Telefonszám")]), _c('div', {
    staticClass: "text-body-1"
  }, [_vm._v(_vm._s(_vm.form.phone_number || '-'))])]), _c('v-col', [_c('div', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Vendégkitűző száma")]), _c('div', {
    staticClass: "text-body-1"
  }, [_vm._v(_vm._s(_vm.value.badge_number || '-'))])])], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "mt-4"
  }, [_c('v-row', [_c('v-col', [_c('div', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Megjegyzés")]), _c('div', {
    staticClass: "text-body-1"
  }, [_vm._v(_vm._s(_vm.value.remark || '-'))])])], 1)], 1), _c('v-divider'), JSON.parse((_vm$value = _vm.value) === null || _vm$value === void 0 ? void 0 : _vm$value.extra_fields).length ? _c('v-card-text', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "pa-0 mb-3"
  }, [_vm._v(" Extra mezők ")]), _vm._l(JSON.parse((_vm$value2 = _vm.value) === null || _vm$value2 === void 0 ? void 0 : _vm$value2.extra_fields), function (field, index) {
    return _c('v-row', {
      key: 'guest-extra-field-' + index
    }, [_c('v-col', [_c('div', {
      staticClass: "font-weight-bold"
    }, [_vm._v(_vm._s(field.key))]), _c('div', {
      staticClass: "text-body-1"
    }, [_vm._v(_vm._s(field.value || '-'))])])], 1);
  })], 2) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }