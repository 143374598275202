var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "value": _vm.isOpen,
      "persistent": "",
      "max-width": "720",
      "scrollable": ""
    }
  }, [_c('v-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.entry.apply(null, arguments);
      }
    }
  }, [_c('v-card', [_c('v-card-title', [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-account-edit")]), _vm._v(" Új vendégbelépés "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.isOpen = false;
      }
    }
  }, [_c('v-icon', [_vm._v(" mdi-close ")])], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticStyle: {
      "max-height": "calc(100vh - 200px)"
    }
  }, [_c('v-autocomplete', {
    staticClass: "ma-2",
    attrs: {
      "label": "Kapu",
      "items": _vm.gates,
      "filled": "",
      "item-text": "name",
      "item-value": "element_id",
      "error-messages": _vm.errors.entry_gate_id,
      "hint": "Kötelező",
      "persistent-hint": ""
    },
    model: {
      value: _vm.form.entry_gate_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "entry_gate_id", $$v);
      },
      expression: "form.entry_gate_id"
    }
  }), _c('v-text-field', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: _vm.$config.inputMasks.name,
      expression: "$config.inputMasks.name"
    }],
    staticClass: "ma-2",
    attrs: {
      "label": "Vendég neve",
      "filled": "",
      "error-messages": _vm.errors.name,
      "persistent-hint": "",
      "hint": "Kötelező"
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }), _vm._l(_vm.extraFields, function (field) {
    return _c('dynamic-field', {
      key: `extra-field-${field.name}`,
      staticClass: "ma-2",
      attrs: {
        "field": field,
        "error-messages": _vm.errors[field.name],
        "filled": ""
      },
      model: {
        value: _vm.form.extra_fields[field.name],
        callback: function ($$v) {
          _vm.$set(_vm.form.extra_fields, field.name, $$v);
        },
        expression: "form.extra_fields[field.name]"
      }
    });
  }), _c('v-textarea', {
    staticClass: "ma-2",
    attrs: {
      "label": "Megjegyzés",
      "filled": "",
      "error-messages": _vm.errors.remark,
      "rows": "3",
      "auto-grow": ""
    },
    model: {
      value: _vm.form.remark,
      callback: function ($$v) {
        _vm.$set(_vm.form, "remark", $$v);
      },
      expression: "form.remark"
    }
  })], 2), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "type": "submit",
      "large": "",
      "color": "primary",
      "depressed": "",
      "loading": _vm.loading
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-check")]), _vm._v(" Mentés ")], 1), _c('v-spacer')], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }